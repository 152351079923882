<template>
    <div class="box">
        <div
            class="box-toolbar"
            style="float:left;"
        >
            <router-link
                v-if="$can('admin/money/create')"
                :to="{ path: '/money/create' }"
            >
                <el-button
                    type="primary"
                    size="medium"
                >
                    新建储值选项数据
                </el-button>
            </router-link>
            <router-link
                v-if="$can('admin/explain/explain')"
                :to="{ path: '/money/explain' }"
            >
                <el-button
                    style="margin-left:20px;"
                    type="primary"
                    size="medium"
                >
                    编辑说明
                </el-button>
            </router-link>
        </div>
        <el-table
            border
            height="70vh"
            v-loading="loading"
            :stripe="true"
            :data="lists"
        >
            <el-table-column
                width="90"
                class-name="text-mono"
                prop="id"
                label="Id"
            >
            </el-table-column>

            <el-table-column
                prop="price"
                label="充值面值"
            >
            </el-table-column>

            <el-table-column
                prop="add_price"
                label="另赠送"
            >
            </el-table-column>

            <el-table-column
                prop="price_des"
                label="充值描述"
            >
            </el-table-column>

            <el-table-column
                prop="add_price_des"
                label="另赠送描述"
            >
            </el-table-column>

            <el-table-column
                prop="sort"
                label="显示顺序"
            >
            </el-table-column>

            <el-table-column
                prop="isHome"
                label="是否显示"
            >
                <template slot-scope="scope">
                    <el-button
                        plain
                        size='mini'
                        type="primary"
                        v-if="scope.row.isHome == 1"
                    >启用中</el-button>
                    <el-button
                        plain
                        size="mini"
                        type="danger"
                        v-if="scope.row.isHome == 0"
                    >已关闭</el-button>
                </template>
            </el-table-column>

            <el-table-column
            width="258"
            label="操作"
             fixed="right">
                <template slot-scope="scope">
                    <el-button
                        v-if="$can('admin/money/money-home')"
                        size="mini"
                        @click.native="Home(scope.row)"
                        style="margin-right:10px;"
                    >
                        设为首页按钮显示
                    </el-button>
                    <el-dropdown trigger="click">
                        <el-button
                            plain
                            size="mini"
                            type="primary"
                        >
                            操作<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                v-if="$can('admin/money/update')"
                                @click.native="handleModelEdit(scope.row)"
                            >
                                编辑
                            </el-dropdown-item>
                            <el-dropdown-item
                                v-if="$can('admin/money/delete')"
                                @click.native="handleModelDelete(scope.row)"
                            >
                                删除
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column>
        </el-table>
        <div class="bp-pages">
            <page
                :pageSize="limit"
                :count="count"
                @change="pageChange"
                @changeSize="changeSize"
            >
            </page>
        </div>
    </div>
</template>

<script>
import MoneyService from '@admin/services/MoneyService'
import pagination from '@admin/mixins/PaginationMixin'
import Page from '@admin/components/Pagination'
import flatry from '@admin/utils/flatry'

export default {
  name: 'MoneyHome',
  components: { Page },
  mixins: [pagination],
  data () {
    return {
      loading: true,
      function: function (param) {
        return MoneyService.all(param)
      },
      delete: function (param) {
        return MoneyService.del(param)
      }
    }
  },
  methods: {
    async Home (e) {
      const { data } = await flatry(
        MoneyService.home({ id: e.id, time: new Date() })
      )
      if (data) {
        this.getList()
        this.$message.success(data.msg)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
// .diao{
//   min-height: 600px;
//   height: 600px;
// }
</style>
